import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux"; // Asumiendo que usas Redux
import { notificationActions } from "../store/actions";

export function RoutesListener() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // Despacha una acción cada vez que cambia la ubicación
    console.log('location.pathname:::',location.pathname)
    if(!location.pathname.includes("/auth")){
      dispatch(notificationActions.GetNotificationsUser({}));
    }
  }, [dispatch, location.pathname]);

  return null; // Este componente no renderiza nada
}
